var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-modal",
    {
      attrs: {
        id: _vm.MODAL_ID,
        title: _vm.modalTitle,
        centered: "",
        "ok-title": "Save",
      },
      on: { ok: _vm.handleSaveBtn },
    },
    [
      _c("div", { staticClass: "mb-3" }, [
        _vm._v("\n    Do you want to save thumbnail for "),
        _c("strong", [_vm._v(_vm._s(_vm.template?.name))]),
        _vm._v(" template?\n  "),
      ]),
      _vm._v(" "),
      _vm.template?.image_url
        ? _c("div", [
            _c("div", { staticClass: "template-thumbnail" }, [
              _c(
                "div",
                { staticClass: "template-thumbnail__image-container" },
                [
                  _c("img", {
                    attrs: { src: _vm.thumbnail, alt: "template-thumbnail" },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "template-thumbnail__download",
                  attrs: {
                    href: `/${_vm.template.image_url}`,
                    download: _vm.template.name,
                    target: "_blank",
                  },
                },
                [_c("i", { staticClass: "bi bi-cloud-arrow-down" })]
              ),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "mt-3 text-center font-weight-bold" }, [
              _c("i", { staticClass: "bi bi-exclamation-circle text-warning" }),
              _vm._v(
                "\n      Saving thumbnail will overwrite existing thumbnail.\n    "
              ),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }