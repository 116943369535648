import { match } from '@frontend/store/psd-layers/features/auto-map/matchers/match';

const IMAGE_AREA_ACTIVATOR_REGEX = /image area/i;
const IMAGE_AREA_KEYPHRASES = [
  'image area',
  'background'
];

const matcher = ({ targetName, sourceName }) => match({
  targetName,
  sourceName,
  keyPhrases: IMAGE_AREA_KEYPHRASES,
});

export const imageAreaMatcher = {
  match: matcher,
  activator: (targetName) => !!targetName.match(IMAGE_AREA_ACTIVATOR_REGEX)
}