import {
  FIELD_TYPE_BACKGROND_THEME_IMAGE,
  FIELD_TYPE_MULTI_LINE_TEXT,
  FIELD_TYPE_PRODUCT_IMAGE
} from '@frontend/constants/type-fields-of-template';
import { headlineMatcher } from '@frontend/store/psd-layers/features/auto-map/matchers/headline-matcher';
import { imageAreaMatcher } from '@frontend/store/psd-layers/features/auto-map/matchers/image-area-matcher';
import { productImageMatcher } from '@frontend/store/psd-layers/features/auto-map/matchers/product-image-matcher';

export const FILL_EXISTED_TEMPLATE_MAP_RULES = {
  [FIELD_TYPE_BACKGROND_THEME_IMAGE]: [
    imageAreaMatcher
  ],
  [FIELD_TYPE_MULTI_LINE_TEXT]: [
    headlineMatcher,
  ],
  [FIELD_TYPE_PRODUCT_IMAGE]: [
    productImageMatcher
  ]
};