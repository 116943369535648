import { match } from '@frontend/store/psd-layers/features/auto-map/matchers/match';

const PRODUCT_IMAGE_ACTIVATOR_REGEX = /product image/i;
const PRODUCT_IMAGE_KEYPHRASES = ['product', 'product image'];

const matcher = ({ targetName, sourceName }) => match({
  targetName,
  sourceName,
  keyPhrases: PRODUCT_IMAGE_KEYPHRASES,
  options: { includeNumber: true }
});

export const productImageMatcher = {
  match: matcher,
  activator: (targetName) => !!targetName.match(PRODUCT_IMAGE_ACTIVATOR_REGEX),
}