import {resolveDataTranslator} from "../../components/modules/file-browser/data-translators";
import {FILE_TYPE} from "../../constants/file-image-types";
import axios from "axios";

let abortController = new AbortController()
export default {
  listBySource(source, params = {}) {
    const translator = resolveDataTranslator(source)
    abortController.abort();
    abortController = new AbortController()
    const promise = axios.get(source, {
      signal: abortController.signal,
      params,
    })

    if (translator) {
      promise.then((response) => {
        response.data.items = response.data.items.map(translator);
      })
    }

    return promise
  },
  getFileList (params = {}) {
    return axios.get('/file/file_list', { params })
  },
  getFolderAvailablePermissions () {
    return axios.get(`/file/folder-available-permissions`)
  },
  getFolderPermissionsData (folderIds, type) {
    return axios.get(`/file/folder-permissions-data`, {
      params: {
        folderIds: folderIds.join(','),
        type
      }
    })
  },
  syncFolderPermissions(folderIds, userIds, userGroupIds, type) {
    return axios.post(`/file/sync-folder-permissions`, { userIds, userGroupIds, folderIds, type });
  },
  previewRequest(fileIds) {
    return axios.post('/file/view', { file_ids: fileIds });
  },

  addFolder(parent_id, url) {
    return axios.post(url, {parent_id});
  },

  updateFolder(folderId, folderName, folderCompanyId) {
    return axios.put('/file/update_folder', { folderId, folderName, folderCompanyId });
  },

  storeStockTags(postData) {
    return axios.post('/file/stock_tags', postData);
  },

  fetchStockTags() {
    return axios.get('/file/stock_tags');
  },

  deleteFolder(folderId) {
    return axios.delete('/file/delete_folder', { data: { folderId } });
  },

  deleteProductFolder(folderId) {
    return axios.delete('/file/delete_product_folder', { data: { folderId } });
  },

  downloadFolder(folderId, url = null) {
    if (url) {
      return axios.post('/file/share/files/download_folder', { folderId, url });
    } else {
      return axios.post('/file/download_folder', { folderId });
    }
  },

  downloadFolderFiles(fileId, url = null) {
    if (url) {
      return axios.post('/file/share/files/download_folder_files', { fileId, url });
    } else {
      return axios.post('/file/download_folder_files', { fileId });
    }
  },

  uploadFolderFile(formData) {
    return axios.post('/file/upload_folder_file', formData);
  },

  uploadProductFile(formData, onUploadProgress) {
    return axios.post('/file/uploadimg/upload_images', formData, {
      onUploadProgress: (event) => onUploadProgress(event),
      headers: {
        'X-Requested-With': 'XMLHttpRequest'
      }
    })
      .then((response) =>
        response.data.paths.map((url, index) => ({
          name: response.data.filenames[index],
          url: `${location.origin}/share?file=${url}`,
        }))
      )
      .catch(() => {
        this.errorString = 'Something went wrong. Try again later.'
      });
  },

  storeExternalResourceToFolderFile(postData) {
    return axios.post('/file/store_external_resources_folder_file', postData);
  },

  getMultipartUploadedParts(formData) {
    return axios.get('/file/upload_folder_file/' + formData.uploadId + '?key=' + formData.key);
  },

  signPartUpload(postData) {
    return axios.get('/file/upload_folder_file/' + postData.uploadId + '/' + postData.partNumber + '?key=' + postData.key);
  },

  completeMultipartUpload(postData) {
      return axios.post('/file/upload_folder_file/' + postData.uploadId + '/complete', postData);
  },

  abortMultipartUpload(postData) {
    return axios.delete('/file/upload_folder_file/' + postData.uploadId + '?key=' + postData.key + '&id=' + postData.id);
  },

  deleteFolderFile(fileId) {
    return axios.delete('/file/delete_folder_file', { data: { fileId } });
  },

  deleteStockFile(fileId, filePath) {
    return axios.delete('/file/delete_stock_file', { data: { fileId, filePath } });
  },

  deleteProductFile(fileId) {
    return axios.delete('/file/delete_product_file', { data: { fileId } });
  },

  deleteMultipleProductFile(fileId, folderId = []) {
    return axios.delete('/file/delete_multiple_product_file', { data: { fileId, folderId } });
  },

  deleteMultipleFolderFile(fileId) {
    return axios.delete('/file/delete_multiple_folder_file', { data: { fileId } });
  },

  convertFolderFile(fileId, format) {
    return axios.post('/file/convert_folder_file', { fileId, format });
  },

  compressFolderFile(fileId, options) {
    return axios.post('/file/compress_folder_file', { fileId }, options);
  },

  convertFolderFileUsingBackgroundJob(fileId, format, folderId) {
    return axios.post('/file/convert_folder_file_using_background_job', { fileId, format, folderId });
  },
  
  resizeFolderFile(fileId, option) {
    return axios.post('/file/resize_folder_file', { fileId, ...option });
  },

  resizeFolderFileUsingBackgroundJob(fileId, option) {
    return axios.post('/file/resize_folder_file_using_background_job', { fileId, ...option });
  },

  compressFolderFileUsingBackgroundJob(fileId, options) {
    return axios.post('/file/compress_folder_file_using_background_job', { fileId }, options);
  },

  updateFolderFile(data) {
    return axios.put('/file/update_folder_file', data);
  },
  
  dropFileFolder(data){
    return axios.put('/file/drop_folder_file', data);
  },

  updateFileFolderFile(data) {
    return axios.put('/file/update_file_folder_file', data);
  },
  
  download(file_ids) {
    return axios.post('/file/download', { file_ids })
  },

  createShareLink(data) {
    return axios.post('/file/share/link', data)
  },

  updateShareLink(data) {
    return axios.post('/file/share/update_link', data)
  },

  getSettingsValue() {
    return axios.get('/file/compress_settings')
  },

  saveSettingsValue(setting_value) {
    return axios.post('/file/compress_settings', setting_value)
  },
  
  sendMail(subject, body, email, shareLink) {
    return axios.post(`/send_mail`, { subject, body, email, shareLink })
  },

  saveRemoveBGImage(data) {
    return axios.post('/video/remove-bg-image', data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },

  getIconPath(extension) {
    return axios.get(`/file/get_icon_path/${extension}`)
  },
  
  addPSDAssetFolder(data) {
    return axios.post('/file/add_psd_asset_folder', data)
  },

  updateThumbnails(file_ids) {
    return axios.post('/file/update_thumbnails', { file_ids })
  },
  saveCropImages(itemIds, replace) {
    return axios.post('/file/crop_image', { itemIds, replace })
  },
  getBreadcrumbs(folderIds, tab) {
    return axios.post(`/file/breadcrumbs/`, { folderIds: folderIds, tab: tab });
  },
  
  duplicateFiles(file_ids) {
    return axios.post(`/file/duplicate`, { file_ids });
  },
  
  storeProductFiles(data) {
    return axios.post(`/file/uploadimg/store`, data);
  },

  addToFavorites (ids = [], verbose = null) {
    return axios.post(`/file/favorite/add`, { ids, verbose })
  },

  removeFromFavorites (ids = [], verbose = null) {
    return axios.post(`/file/favorite/remove`, { ids, verbose })
  },

  checkProductFilesExist(data) {
    return axios.post(`/file/uploadimg/check-exist-product-fiels`, data);
  },

  itemsPerPage (value = null) {
    return axios.get('/file/items-per-page', { params: { value } })
  },
};
