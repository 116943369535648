var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "c-top-bar" }, [
    _c(
      "div",
      {
        staticClass:
          "c-top-bar__search_container d-flex flex-grow-1 align-items-center",
      },
      [
        _c("div", { staticClass: "c-top-bar__search" }, [
          _c(
            "svg",
            {
              staticClass: "c-top-bar__search--icon",
              attrs: { width: "13", height: "13" },
            },
            [_c("use", { attrs: { href: "/img/icons/sprite.svg#search-big" } })]
          ),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchInput,
                expression: "searchInput",
              },
            ],
            staticClass: "c-top-bar__search--input",
            attrs: { type: "text", placeholder: "Search" },
            domProps: { value: _vm.searchInput },
            on: {
              keyup: _vm.debouncedSearchHandler,
              input: function ($event) {
                if ($event.target.composing) return
                _vm.searchInput = $event.target.value
              },
            },
          }),
        ]),
        _vm._v(" "),
        _vm.isGoToTopAvailable
          ? _c(
              "b-btn",
              {
                staticClass:
                  "c-top-bar__reset-button c-top-bar__settings-button c-top-bar__search--button ml-2",
                on: { click: _vm.goBack },
              },
              [_c("i", { staticClass: "cil-arrow-thick-from-bottom" })]
            )
          : _vm._e(),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex align-items-center" }, [
      _c(
        "div",
        { staticClass: "d-flex justify-content-end align-items-center" },
        [
          _c(
            "b-form-checkbox",
            {
              attrs: { disabled: !_vm.isTopBarSwitchersEnabled, switch: "" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
              model: {
                value: _vm.isFolderViewEnabledLocal,
                callback: function ($$v) {
                  _vm.isFolderViewEnabledLocal = $$v
                },
                expression: "isFolderViewEnabledLocal",
              },
            },
            [_vm._v("\n        Show folders\n      ")]
          ),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "d-flex justify-content-end align-items-center" },
      [
        _c(
          "b-form-checkbox",
          {
            attrs: {
              disabled: !_vm.isTopBarSwitchersEnabled || !_vm.isAnyPicked,
              switch: "",
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
              },
            },
            model: {
              value: _vm.showOnlySelectedLocal,
              callback: function ($$v) {
                _vm.showOnlySelectedLocal = $$v
              },
              expression: "showOnlySelectedLocal",
            },
          },
          [_vm._v("\n      Show selected\n    ")]
        ),
      ],
      1
    ),
    _vm._v(" "),
    !_vm.isShowAllFromCanvas
      ? _c(
          "div",
          [
            _c(
              "ButtonPrimary",
              {
                attrs: { variant: "primary" },
                on: { click: _vm.handleShowAll },
              },
              [_vm._v("\n      Show all\n    ")]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }