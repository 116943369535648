/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

// CoreUI
require("@coreui/coreui");

require("../bootstrap");
require("../plugins");
require("./lib/fabric-overrides");
require("multiple-select")
require("./lib/no-scroll")

function getBox(width, height) {
  return {
      string: "+",
      style: "font-size: 1px; padding: " + Math.floor(height/2) + "px " + Math.floor(width/2) + "px; line-height: " + height + "px;"
  }
}

console.image = function(url, scale) {
  scale = scale || 1;
  var img = new Image();

  img.onload = function() {
      var dim = getBox(this.width * scale, this.height * scale);
      console.log("%c" + dim.string, dim.style + "background: url(" + url + "); background-size: " + (this.width * scale) + "px " + (this.height * scale) + "px; color: transparent;");
  };

  img.src = url;
};

window.rememberTemplateSettings = function () {
  const customer_id = $('input[name="customer_id"]').val();
  const key = "customer_" + customer_id + "_settings";
  if (localStorage.getItem(key)) {
    var settings = JSON.parse(localStorage.getItem(key));
    const template_id = $('input[name="template_id"]').val();
    const formData = $("#adForm").serializeArray();
    const queryString = JSON.stringify(formData);
    settings["template_" + template_id] = queryString;
    settings["last_template"] = template_id;
    localStorage.setItem(key, JSON.stringify(settings));
  }
};

window.windowStatusInterval = null;
window.checkStatus = function() {
    axios.post('/session-status', {} , {
    headers: {
        'Content-Type': 'application/json',
    }
  }).then(function(result) {

      let flashMessage = ' <div style="width: 100% !important;" class="alert alert-danger alert-dismissible fade show" role="alert">\n'+
                      '                    <strong>Your session has expired, please re-login.</strong>\n'+
                      '                    <button type="button" class="close" data-dismiss="alert" aria-label="Close">\n'+
                      '                        <span aria-hidden="true">&times;</span>\n'+
                      '                    </button>\n'+
                      '                </div>';

      if(result.data.status != 200) {
          clearInterval(window.windowStatusInterval)
          //if opened model
          if($('.modal.fade.show').length) {
                $('.modal.fade.show').find('.modal-content').prepend(flashMessage);
          } else {
                $('div#app').find('.c-main').prepend(flashMessage);
          }
      }
  });
}

window.forgetTemplateSettings = function (customer_id = "", template_id="") {
  axios.delete('/user/color/reset').then((res) => {
    if (customer_id == "")
      customer_id = $('input[name="customer_id"]').val();
    const key = "customer_" + customer_id + "_settings";

    if (localStorage.getItem(key)) {

      if (template_id == ""){
        localStorage.removeItem(key);
      }else{
        // just clear settings for this particlar template
        var settings = JSON.parse(localStorage.getItem(key));
        if (settings) {
          settings["template_" + template_id + "_edited"] = '{}';
          settings["template_" + template_id + "_files"] = '{}';
          settings["template_" + template_id ] = '[]';

          localStorage.setItem(key, JSON.stringify(settings));
        }

      }

    }

    if (localStorage.getItem("selected_files")) {
      localStorage.removeItem("selected_files");
    }

    if (localStorage.getItem("hero_image_position")) {
      localStorage.removeItem("hero_image_position");
    }

    for (var i=0 ; i<5 ; i++){
      if (localStorage.getItem("image_position_"+i)) {
        localStorage.removeItem("image_position_"+i);
      }

    }

    var name = "file_ids";
    var value = "";
    var expires = "";
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  });
};

import Vue from "vue";
import store from "./store";
import router from './router'
import VueLazyload from 'vue-lazyload'
import vueCustomElement from 'vue-custom-element'
import VModal from 'vue-js-modal'

import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

import "../../sass/includes/bootstrap-vue.scss"
import "toastr/build/toastr.css";

import contenteditable from 'vue-contenteditable'
export const bus = new Vue();

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueLazyload);
Vue.use(vueCustomElement);
Vue.use(VModal);
Vue.use(contenteditable)

Vue.component('DashboardPage', () => import('./components/DashboardPage.vue'))
Vue.component('PromptGeneratorPage', () => import('./components/PromptGeneratorPage.vue'))
Vue.component('VideoTable', () => import('./components/VideoTable.vue'))
Vue.component('FileBrowser', () => import('./components/modules/file-browser/FileBrowser.vue'))
Vue.component('LayoutFolderBrowser', () => import('./components/modules/layout-folder-browser/FileBrowser.vue'))
Vue.component('FileBrowserProductSelector', () => import('./components/modules/file-browser-product-selector/FileBrowser.vue'))
Vue.component('FileUploadImages', () => import('./components/modules/file-upload-images/UploadImages.vue'))
Vue.component('ShareImage', () => import('./components/ShareImage.vue'))
Vue.component('ShareVideo', () => import('./components/ShareVideo.vue'))
Vue.component('ShareProofVideo', () => import('./components/ShareProofVideo.vue'))
Vue.component('ProjectShareUpload', () => import('./components/ProjectShareUpload.vue'))
Vue.component('OutlineFieldsModal', require('./components/modules/layouts-page/OutlineFieldsModal.vue').default);
Vue.component('ExportTemplatesModal', require('./components/modules/layouts-page/ExportTemplatesModal.vue').default);
Vue.component('NamingTableVariablesModal', require('./components/modules/layouts-page/modals/NamingTableVariablesModal.vue').default);
Vue.component('LayoutSettingsModalExportTab', require('./components/modules/layouts-page/LayoutSettingsModal/ExportTab.vue').default);
Vue.component('LayoutSettingsModalLayoutsTab', require('./components/modules/layouts-page/LayoutSettingsModal/LayoutsTab.vue').default);
Vue.component('ReplaceTextModal', require('./components/modules/layouts-page/modals/ReplaceTextModal.vue').default);
Vue.component('CopyAndResizeTemplateModal', require('./components/modules/layouts-page/modals/CopyAndResizeTemplateModal.vue').default);
Vue.component('SaveAsTemplateModal', require('./components/modules/layouts-page/modals/SaveAsTemplateModal.vue').default);
Vue.component('SelectStrategyImportFromPsd', require('./components/modules/layouts-page/modals/SelectStrategyImportFromPsd.vue').default);
Vue.component('MaskSettingsModal', require('./components/modules/layouts-page/modals/MaskSettingsModal.vue').default);
Vue.component('RenameJobModal', require('./components/modals/RenameJobModal.vue').default);
Vue.component('LayoutFolderBrowserModal', require('./components/modals/LayoutFolderBrowserModal.vue').default);
Vue.component('GenerateTemplateThumbnailModal', require('./components/modules/layouts-page/modals/GenerateTemplateThumbnailModal.vue').default);

/**
 * Web Components for Vue 2
 * @see https://github.com/karol-f/vue-custom-element
 */
Vue.customElement('custom-template-size-modal-ce', require('./components/modules/layouts-page/CustomTemplateSizeModal.vue').default)
Vue.customElement('background-maker-ce', require('./components/BackgroundMaker.vue').default)
Vue.customElement('layout-sidebar-ce', require('./components/modules/layouts-page/LayoutSidebar.vue').default)
Vue.customElement('background-theme-color-ce', require('./components/modules/layouts-page/BackgroundThemeColor.vue').default)
Vue.customElement('psd-preview-ce', require('./components/modules/psd-layers/PSDPreview.vue').default)
Vue.customElement('svg-preview-ce', require('./components/modules/svg-editor/SVGEditor.vue').default)
Vue.customElement('upload-modal-ce', require('./components/modals/UploadModal.vue').default)
Vue.customElement('layout-theme-modal-ce', require('./components/modals/LayoutThemeModal.vue').default)
Vue.customElement('keyword-analytics-ce', require('./components/KeywordAnalytics.vue').default)
Vue.customElement('product-descriptions-compare-ce', require('./components/ProductDescriptionsCompare.vue').default)
Vue.customElement('layout-folder-select-ce', require('./components/modules/layouts-page/LayoutFolderSelect.vue').default)
Vue.customElement('svg-preview-messages-ce', require('./components/modules/svg-editor/SVGEditorMessages.vue').default)

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
  store,
  router,
});

router.onReady(() => {
    app.$mount("#app")
})

Vue.prototype.$store = store
Vue.prototype.$myToast = {
  success: () => {
    console.log("success");
  },
  failed: () => {
    console.log("failed");
  },
};


$(document).ready(function () {
    const checkStatus = $('body').attr('data-check-status');
    if (checkStatus !== undefined) {
      window.windowStatusInterval =  setInterval('window.checkStatus()', 30000);
      $('#sidebar').on('classtoggle', function (event) {
        let isSideBarOpen = $('#sidebar').hasClass('c-sidebar-lg-show') || $('#sidebar').hasClass('c-sidebar-show')  ? 'open' : 'closed';
        let variant = $('#sidebar').hasClass('c-sidebar-minimized') ? 'c-sidebar-minimized' : '';
        axios(`/toggle-options?sidebarState=${isSideBarOpen}&sidebarMinimizeState=${variant}`);
      });
    }
});

$("#showMe").click((e) => {
  e.preventDefault();
  
  if ($("#password").attr("type") === "password") {
    $("#showMe").removeClass("bi-eye").addClass("bi-eye-slash");
    $("#password").attr('type', 'text');
  } else if ($("#password").attr("type") === 'text') {
    $("#showMe").removeClass("bi-eye-slash").addClass("bi-eye");
    $("#password").attr("type", "password");
  }
})

$("#showMeConfirmation").click((e) => {
  e.preventDefault();

  if ($("#password_confirmation").attr("type") === "password") {
    $("#showMeConfirmation").removeClass("bi-eye").addClass("bi-eye-slash");
    $("#password_confirmation").attr('type', 'text');
  } else if ($("#password_confirmation").attr("type") === 'text') {
    $("#showMeConfirmation").removeClass("bi-eye-slash").addClass("bi-eye");
    $("#password_confirmation").attr("type", "password");
  }
})


$(document).ready(() => {
  // Triggers when existing sidebar events are called
  $('#sidebar').on('classtoggle', () => {
    if ($('#sidebar').hasClass('c-sidebar-minimized')) {
      if ($('.c-sidebar-nav').hasClass('ps')) {
        $('.c-sidebar-nav').removeClass('ps');
      }
    }
  });

  const gridViewCompany = $("#grid-view-company");
  gridViewCompany.multipleSelect();
});

$(document).ready(() => {
  $( '.c-sidebar-nav' ).hover(
    () => {
      if ($('#sidebar').hasClass('c-sidebar-minimized')) {
        if ($('.c-sidebar-nav').hasClass('ps')) {
          $('.c-sidebar-nav').removeClass('ps');
        }
      }
    }
  );

  const headerDropdown = document.getElementById("headerDropdown");
  if (headerDropdown) {
      headerDropdown.addEventListener("shown.coreui.dropdown", () => {
          $(".bi").addClass("bi__show");
      });

      headerDropdown.addEventListener("hidden.coreui.dropdown", () => {
          $(".bi").removeClass("bi__show");
      });
  }
});
