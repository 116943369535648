import { match } from '@frontend/store/psd-layers/features/auto-map/matchers/match';

const HEADLINE_ACTIVATOR_REGEX = /headline/i;
const HEADLINE_KEYPHRASES = ['headline'];

const matcher = ({ targetName, sourceName }) => match({
  targetName,
  sourceName,
  keyPhrases: HEADLINE_KEYPHRASES,
  options: { includeNumber: true }
});

export const headlineMatcher = {
  match: matcher,
  activator: (elementName) => !!elementName.match(HEADLINE_ACTIVATOR_REGEX)
}