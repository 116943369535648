import {FILE_TYPE} from "../constants/file-image-types";
import {captureVideoFrame} from "./helpers/generage-video-preview";
import FileBrowserService from './api/file-browser'
import { IMAGE_TYPE_PRODUCT } from "@frontend/constants/file-upload-images-types";
import router from "@frontend/router";

const MIMES_MAP = {
    [FILE_TYPE.PSD]: 'image/vnd.adobe.photoshop',
    [FILE_TYPE.MOV]: 'video/quicktime',
}

export const getMimeType = file => {
  if (file.type) {
    return file.type;
  }

  const parts = file.name.split('.');
  const extension = parts[parts.length - 1];

  return MIMES_MAP[extension];
}

export const uploadFolderFile = async (files, folderId, isReplace = 0) => {
    const fileInfo = []

    for (const key in files) {
        if (key === 'length') {
          continue;
        }

        const file = files[key];
        
        const mimetype = getMimeType(file);

        if (!file.name || !mimetype) {
            continue;
        }

        fileInfo.push({
            key,
            name: file.name,
            type: mimetype,
            size: file.size,
            replace_name: file.replace_name ?? '',
        })

        if (file.company_id) {
            fileInfo[fileInfo.length - 1]['company_id'] = file.company_id
        }

        if (file.type.includes('video')) {
            fileInfo[fileInfo.length - 1]['image'] = await captureVideoFrame(file)
        }
    }

    const formData = new FormData();
    formData.append('file_info', JSON.stringify(fileInfo));
    formData.append('folderId', folderId);
    formData.append('isReplace', isReplace);

    const {data} = await FileBrowserService.uploadFolderFile(formData);

    return data
}

export const uploadFile = (uploadUrl, file, onProgress, method = "PUT") => new Promise((resolver, rejecter) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = () => {
        if (xhr.status === 200) {
            resolver();
        } else {
            const error = new Error(xhr.response);
            rejecter(error);
        }
    };
    xhr.onerror = (error) => {
        rejecter(error);
    };
    xhr.upload.onprogress = (evt) => {
        onProgress?.(evt.loaded / evt.total);
    };
    xhr.open(method, uploadUrl);
    xhr.send(file);
});

export const prepareProductFileForm = (payload) => {
  const folderId = Number(router.currentRoute.params.pathMatch?.split('/').pop());
  const files = [];
  const replace_file_with_same_name = [];
  
  for (const key in payload.files) {
    if (key === 'length') {
      continue;
    }

    const file = payload.files[key];
    const mimetype = getMimeType(file);

    if (!file.name || !mimetype) {
      continue;
    }

    files.push(file);
    replace_file_with_same_name.push(file.name);
  }

  const formFields = {
    image_type: IMAGE_TYPE_PRODUCT,
    upload_images: files,
    upload_images_url: '',
    company_id: -1,
    replace_file_with_same_name: payload.selected ==  1 ? replace_file_with_same_name : [],
    generated_from_psd: '',
    folder_id: folderId || '',
    convert: 'png',
    max_size: 5000,
    resize: 99,
    dimension_width: 0,
    dimension_height: 0,
    has_dimension_aspect_ratio: false,
    name: '',
    dpi: 75,
    background_remove: payload.isRemoveBackground,
    crop_to_image: payload.isCropToImage,
    is_compress: false,
    is_resize: false,
    is_convert: false,
    is_rename: false,
    is_dpi: false,
  }

  const formData = new FormData();

  for (const key in formFields) {
    const value = formFields[key];

    if (Array.isArray(value)) {
      value.forEach(field => {
        formData.append(`${key}[]`, field);
      });

    } else {
      formData.append(key, value);

    }
  }
  
  return formData;
}
