import LAYOUT_API from "@frontend/services/api/layout";
import {
  editorControlEventBroker,
  EVENTS
} from "@frontend/group/modules/editor-control-popup/event-broker";
import store from "@frontend/store";
import {addAddField, DEFAULT_TEXT_FIELD_PROPERTIES, DEFAULT_MULTI_LINE_TEXT_FIELD_PROPERTIES} from "@frontend/group/modules/additional-fields";

export const LOADING_TYPE = {
  COPY_AND_TRANSLATE: 'loadingCopyAndTranslate', 
  COPY_FIELDS_AND_TRANSLATE: 'loadingCopyFieldsAndTranslate',
  TRANSLATE: 'loadingTranslate'
}

export const HANDLER_TYPES = {
  COPY_AND_TRANSLATE: 'COPY_AND_TRANSLATE',
  COPY_FIELDS_AND_TRANSLATE: 'COPY_FIELDS_AND_TRANSLATE',
  TRANSLATE: 'TRANSLATE'
} 

const HANDLERS = {
  COPY_AND_TRANSLATE: copyAndTranslate, 
  COPY_FIELDS_AND_TRANSLATE: copyFieldsAndTranslate,
  TRANSLATE: translate
}

async function copyAndTranslate(instance, translateFields, lang) {
  const url = $(`.template-item[data-instance=${instance.id}] .dropdown-item.duplicate-template`).attr('data-url');
  
  await LAYOUT_API.copyAndTranslate(url, {
    translate_target_language: lang,
    translate_fields: translateFields
  });
}

async function copyFieldsAndTranslate(instance, translateFields, lang) {
    const layoutPartPath = location.pathname.replace('show', '')
  
    let translationResult = await LAYOUT_API.getTranslationForFields(layoutPartPath, instance, {
        translate_target_language: lang,
        translate_fields: translateFields
    });

    translationResult = translationResult.data;

    const preview = store.getters['layout/selectedInstance'];
    const textElements = preview.canvas.getObjects('textbox');
  
    const newFieldOptions = {};

    for (const element of textElements) {
      const options = {};
      const newCoordinates = element.getPointByOrigin('top', 'right');
      
      const defaultFieldProperties = element.type === 'Text' 
        ? DEFAULT_TEXT_FIELD_PROPERTIES 
        : DEFAULT_MULTI_LINE_TEXT_FIELD_PROPERTIES;
  
      for (const key of Object.keys(defaultFieldProperties)) {
        for (const dataOption in preview.data) {
          if (dataOption === element.element_id + key) {
            options[key] = preview.data[dataOption];
  
            if (key === '_offset_x') {
              options[key] = newCoordinates.x;
            }
  
            if (key === '_offset_y') {
              options[key] = newCoordinates.y;
            }
          }
        }
      }
      
      options['_translated_from_element_id'] = element.element_id;
      options['text'] = translationResult[element.element_id] ?? 'No translation found.';
  
      newFieldOptions[element.element_id] = options;
    }
    
    for (const key in translationResult) {
      const type = key.includes('multi_line_text') ? 'Multi Line Text' : 'Text';
      
      let alreadyTranslatedField;
      for (const previewDataKey in preview.data) {
        if (previewDataKey.includes('_translated_from_element_id') && preview.data[previewDataKey] === key) {
          alreadyTranslatedField = previewDataKey.split('_translated_from_element_id')[0];
        }
      }

      const alreadyTranslatedElement = alreadyTranslatedField
        ? textElements.find(element => element.element_id === alreadyTranslatedField)
        : null;
      
      if (!alreadyTranslatedElement) {
          const originalElement = textElements.find(element => element.element_id === key);
          if (originalElement) {
            await addAddField(type, preview.id, newFieldOptions[key]);
          }
        }
    }
    preview.canvas.renderAll()
}

async function translate(instance, translateFields, lang) {
  const layoutPartPath = location.pathname.replace('show', '')
  await LAYOUT_API.translate(layoutPartPath, instance, {
    translate_target_language: lang,
    translate_fields: translateFields
  });
}

export async function translateTemplate({textFields, 
                                          multilineTextFields, 
                                          additionalTextFields,
                                          additionalMultilineTextFields, 
                                          instance, 
                                          lang, 
                                          handler}) {
    let loadingType;
    
    if (handler === HANDLER_TYPES.COPY_AND_TRANSLATE) {
        loadingType = LOADING_TYPE.COPY_AND_TRANSLATE;
    } else if (handler === HANDLER_TYPES.COPY_FIELDS_AND_TRANSLATE) {
        loadingType = LOADING_TYPE.COPY_FIELDS_AND_TRANSLATE;
    } else {
        loadingType = HANDLER_TYPES.TRANSLATE;
    }
  
  editorControlEventBroker.fire(EVENTS.TOGGLE_LOADING, { loadingType });
  
  let translateFields = [];
  
  textFields.forEach((field) => {
    translateFields.push(field.element_id);
  });
  
  multilineTextFields.forEach((field) => {
    translateFields.push(field.element_id);
  });

  additionalTextFields.forEach((field) => {
    translateFields.push(field.element_id);
  });

  additionalMultilineTextFields.forEach((field) => {
    translateFields.push(field.element_id);
  });

  await HANDLERS[handler](instance, translateFields, lang);

  editorControlEventBroker.fire(EVENTS.TOGGLE_LOADING, { loadingType });
  
  if (handler !== HANDLER_TYPES.COPY_FIELDS_AND_TRANSLATE) {
    location.reload();
  }
}
