<template>
  <b-modal 
    :id="MODAL_ID"
    :title="modalTitle"
    centered
    ok-title="Save"
    @ok="handleSaveBtn"
  >
    <div class="mb-3">
      Do you want to save thumbnail for <strong>{{ template?.name }}</strong> template?
    </div>
    <div v-if="template?.image_url">
      <div class="template-thumbnail">
        <div class="template-thumbnail__image-container">
          <img
            :src="thumbnail"
            alt="template-thumbnail"
          >
        </div>
        <a 
          class="template-thumbnail__download"
          :href="`/${template.image_url}`" 
          :download="template.name"
          target="_blank" 
        >
          <i class="bi bi-cloud-arrow-down" />
        </a>
      </div>
      <p class="mt-3 text-center font-weight-bold">
        <i class="bi bi-exclamation-circle text-warning" />
        Saving thumbnail will overwrite existing thumbnail.
      </p>
    </div>
  </b-modal>
</template>

<script>
import { generateThumbnailEventBroker, EVENTS } from "@backend/template/generate-template-thumbnail/event-broker";
import { GeneratePreviewImage } from "@frontend/group/generate-preview-image";
import { DESIRED_HEIGHT, DESIRED_WIDTH } from "@backend/template/generate-template-thumbnail";
import templateThumbnailService from '@frontend/services/api/template-thumbnail'

const MODAL_ID = 'generateTemplateThumbnailModal';

export default {
  name: 'GenerateTemplateThumbnailModal',
  data: () => ({
    modalTitle: 'Save thumbnail',
    preview: null,
    template: null
  }),
  computed: {
    MODAL_ID() {
      return MODAL_ID;
    },
    thumbnail() {
      return `/share?file=${this.template?.image_url}`;
    }
  },
  mounted() {
    generateThumbnailEventBroker.on(EVENTS.INIT, this.init.bind(this));
  },
  methods: {
    init({ preview }) {
      this.template = preview?.template;
      this.preview = preview;
      this.$bvModal.show(this.MODAL_ID);
    },
    async handleSaveBtn() {
      const thumbnail = await new GeneratePreviewImage(this.preview.canvas.toCanvasElement())
        .resize(DESIRED_WIDTH, DESIRED_HEIGHT)
        .file('template-thumbnail');
      
      const formData = new FormData();
      formData.append('image', thumbnail);
      formData.append('template_id', this.template.id);
      
      try {
        const { data } = await templateThumbnailService.saveTemplateThumbnail(formData);

        this.template.image_url = data.url;

        toastr.success('Thumbnail has been successfully saved.');
      } catch (error) {
        toastr.error('Something went wrong while generating thumbnail');
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .template-thumbnail {
    border: 1px solid #d8dbe0;
    border-radius: 5px;
    max-width: 300px;
    padding: 5px;
    margin: 0 auto;
    position: relative;
    
    &__image-container {
      position: relative;
      width: 100%;
      padding-top: 150px;
    }
    
    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    
    &__download {
      position: absolute;
      top: -5px;
      right: 5px;
      font-size: 35px;
    }
  }
</style>
