import similarity from "@frontend/services/helpers/similarity-text";

const DEFAULT_MATCH_OPTIONS = {
  includeNumber: false
};

// Utility function to escape regex special characters
function escapeRegexChars(str) {
  return str.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
}

// Function to generate dynamic regex based on options
function generateDynamicRegex(keyPhrase, { includeNumber }) {
  const escapedPhrase = escapeRegexChars(keyPhrase);
  const pattern = includeNumber ? `(${escapedPhrase})(\\s+\\d+)?` : escapedPhrase;

  return new RegExp(`\\b${pattern}\\b`, 'i');
}

// Function to match the best key phrase based on similarity
function matchKeyPhrase(name, keyPhrases) {
  return keyPhrases.reduce((bestMatch, phrase) => {
    const similarityPercent = similarity(name, phrase);

    return similarityPercent > bestMatch.maxSimilarity
      ? { phrase, maxSimilarity: similarityPercent }
      : bestMatch;
  }, { phrase: null, maxSimilarity: 0 }).phrase;
}

// Function to extract number from a regex match
function extractNumber(match) {
  return match && match[2] ? parseInt(match[2], 10) : null;
}

// Main matcher function
export const match = ({ targetName, sourceName, keyPhrases, options = DEFAULT_MATCH_OPTIONS }) => {
  if (!targetName || !sourceName) {
    return false;
  }

  const normalizedSourceName = sourceName.toLowerCase().trim();
  const normalizedTargetName = targetName.toLowerCase().trim();

  if (normalizedSourceName === normalizedTargetName) {
    return true;
  }

  const sourceKeyPhrase = matchKeyPhrase(normalizedSourceName, keyPhrases);
  const targetKeyPhrase = matchKeyPhrase(normalizedTargetName, keyPhrases);

  const sourceMatch = normalizedSourceName.match(generateDynamicRegex(sourceKeyPhrase, options));
  const targetMatch = normalizedTargetName.match(generateDynamicRegex(targetKeyPhrase, options));

  if (!sourceMatch || !targetMatch) {
    return false;
  }

  if (options.includeNumber) {
    const sourceNumber = extractNumber(sourceMatch);
    const targetNumber = extractNumber(targetMatch);

    if (sourceNumber === null) {
      return targetNumber === 1;
    }

    if (targetNumber === null) {
      return sourceNumber === 1;
    }

    return sourceNumber === targetNumber;
  }

  return true;
}
