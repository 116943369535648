import { GeneratePreviewImage } from "@frontend/group/generate-preview-image";
import { generateThumbnailEventBroker, EVENTS } from "@backend/template/generate-template-thumbnail/event-broker";
import templateThumbnailService from '@frontend/services/api/template-thumbnail'

export const DESIRED_WIDTH = 500;
export const DESIRED_HEIGHT = 500;

const thumbnailPreviewContainer = $('.thumbnail-preview');
const thumbnailPreview = $('.thumbnail-preview__image');
const fileInput = $('.form-control-file');
const submitButton = $('#updateImgSubmitBtn');
const downloadButton = $('#download_template_image');
const deleteButton = $('#delete_template_image');

let generatedThumbnail = null;

export function initGenerateThumbnailHandler(canvas) {
  const generateThumbnailBtn = $('#generate_template_thumbnail');
  
  generateThumbnailBtn.on('click', () => handleGenerateThumbnailBtn(canvas));

  generateThumbnailBtn.show();
  toggleDownloadAndRemoveButtons(`/${thumbnailPreviewContainer.data('src')}`);
}

function insertThumbnailPreview(url) {
  const img = new Image();
  img.src = url;

  thumbnailPreview.empty();
  thumbnailPreview.append(img);
  thumbnailPreviewContainer.addClass('active');
}

function toggleDownloadAndRemoveButtons(url) {
  if (thumbnailPreviewContainer.hasClass('active')) {
    downloadButton.attr('href', url);
    downloadButton.show();
    deleteButton.show();
  }
}

async function handleGenerateThumbnailBtn (canvas) {
  const thumbnail = new GeneratePreviewImage(canvas.toCanvasElement())
    .resize(DESIRED_WIDTH, DESIRED_HEIGHT);

  generatedThumbnail = await thumbnail.file('template-thumbnail');
  
  insertThumbnailPreview(thumbnail.url());
  fileInput.fileinput('clear');
  submitButton.prop('disabled', false);
}

async function handleSubmit(event) {
  event.preventDefault();

  const form = $(this).closest('form');
  const inputs = form.find('input');
  const formData = new FormData();

  if (generatedThumbnail) {
    formData.append('image', generatedThumbnail);
  }
  
  inputs.each((index, el) => {
    const element = $(el);

    if (element.attr('type') === 'file' && !generatedThumbnail) {
      const files = element[0].files;

      for(let i = 0; i < files.length; i++) {
        formData.append(element.attr('name'), files[i]);
      }
      
    } else if (element.attr('name')) {
      formData.append(element.attr('name'), element.val());
    }
  });

  try {
    const { data } = await templateThumbnailService.saveTemplateThumbnail(formData);
    
    insertThumbnailPreview(`/share?file=${data.url}`);
    toggleDownloadAndRemoveButtons(`/${data.url}`);
    
    toastr.success('Thumbnail has been successfully saved.');
  } catch (error) {
    console.error(error);
    toastr.error('Something went wrong while generating thumbnail');
  }
}

function handleFileChange() {
  thumbnailPreviewContainer.removeClass('active');
  generatedThumbnail = null;
  submitButton.prop('disabled', false);
}

export function initSaveThumbnailHandler(row, instance, previews) {
  const button = row.find('.save-thumbnail');
  
  button.on('click', function () {
    const preview = previews.find(preview => preview.id === instance.instance_id);
    
    generateThumbnailEventBroker.fire(EVENTS.INIT, { preview });
  });
}

submitButton.on('click', handleSubmit);
fileInput.on('filebatchselected', handleFileChange);
fileInput.on('filecleared', () => submitButton.prop('disabled', true));
