const COEFFICIENT_FOR_MAIN_SCALE = 0.2
const COEFFICIENT_FOR_CTA_SCALE = 0.4

const getScaleByDirection = ({object, dimension, canvas}) => {
  const width = object.getScaledWidth() / canvas.width * dimension.width / object.getScaledWidth();
  const height = object.getScaledHeight() / canvas.height * dimension.height / object.getScaledHeight();

  return {
    width,
    height
  }
}

const getMainScale = (props, coeff = 0) => {
  const { width, height } = getScaleByDirection(props)

  const scale = Math.min(width, height);

  return scale + Math.abs(width - height) * coeff
}

const SPECIFIC_PROPORTION_SETTINGS = {
  CTA_BUTTON: {
    activation: ({ object }) => object?.templateField?.name?.toLowerCase()?.includes('cta'),
    getScale: (props) => getMainScale(props, COEFFICIENT_FOR_CTA_SCALE)
  },
  DEFAULT: {
    activation: ({object, dimension, canvas}) => true,
    getScale: (props) => getMainScale(props, COEFFICIENT_FOR_MAIN_SCALE)
  },
}

export const getObjectScaleFactor = (props) => {
  for (const handlerName in SPECIFIC_PROPORTION_SETTINGS) {
    const handler = SPECIFIC_PROPORTION_SETTINGS[handlerName]
    if (handler.activation(props)) {
      return handler.getScale(props)
    }
  }
}